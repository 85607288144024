import React, { useState } from "react";

import { useJsApiLoader } from "@react-google-maps/api";

import "./Main.scss";
import "../../styles/main/Animations.scss";
import "../../styles/main/Adaptations.scss";

import Loading from "../../components/main/loading/Loading";
import Guide from "../../components/main/guide/Guide";

import Header from "../../components/main/header/Header";
import BottomTips from "../../components/main/bottom-tips/BottomTips";

import Map from "../../components/main/map/Map";

import Card from "../../components/main/card/Card";
import WindowCard from "../../components/main/window-card/WindowCard";
import SmallCard from "../../components/main/small-card/SmallCard";

import { dropdownsData } from "./HeaderData";

export default function Main() {
    const [isMobile, setIsMobile] = useState(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    );

    const [isHeaderActive, setIsHeaderActive] = useState(false);
    const [isBottomActive, setIsBottomActive] = useState(false);

    // Dropdowns data
    const [typeDropdowns, setTypeDropdowns] = useState([
        "asset_management",
        "future_developments",
        "commercial_developments",
        "villas_for_sale",
        "residential_developments",
        "landmarks",
    ]);

    const [directionSwitchChecked, setDirectionSwitchChecked] = useState(false);
    const [radiusSwitchChecked, setRadiusSwitchChecked] = useState(false);
    const [mapTypeChecked, setMapTypeChecked] = useState(false);

    const data = dropdownsData(
        typeDropdowns,
        setTypeDropdowns,

        directionSwitchChecked,
        setDirectionSwitchChecked,
        radiusSwitchChecked,
        setRadiusSwitchChecked,
        mapTypeChecked,
        setMapTypeChecked
    );

    const [currentActiveDropdown, setCurrentActiveDropdown] = useState();
    const [newProjectsSwitchChecked, setNewProjectsSwitchChecked] =
        useState(false);
    const [masterCommunitiesChecked, setMasterCommunitiesChecked] =
        useState(true);

    // Map data
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDqb2XlJJbdHDsu8mDDz4oAAMdevM011bI",
    });

    // Card and Window
    const [activeCard, setActiveCard] = useState(false);
    const [cardContent, setCardContent] = useState({
        title: null,
        desc: null,
        previews: [],
    });
    const [activeWindow, setActiveWindow] = useState(false);
    const [windowContent, setWindowContent] = useState(<h1>loading</h1>);

    // Small Card
    const [activeSmallCard, setActiveSmallCard] = useState(false);
    const [smallCardContent, setSmallCardContent] = useState({
        title: "",
        desc: "",
        image: null,
        link: "",
        coords: [],
    });

    // Guide
    const [activeGuide, setActiveGuide] = useState(true);
    const [activeSmallGuide, setActiveSmallGuide] = useState(false);

    return (
        <div className="main">
            <Loading isLoadedMap={isLoaded} />
            <Guide
                activeGuide={activeGuide}
                setActiveGuide={setActiveGuide}
                isMobile={isMobile}
                setActiveWindow={setActiveWindow}
                setWindowContent={setWindowContent}
            />
            <SmallCard
                activeSmallCard={activeSmallCard}
                smallCardContent={smallCardContent}
                setActiveSmallCard={setActiveSmallCard}
            />
            <WindowCard
                windowContent={windowContent}
                setWindowContent={setWindowContent}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                setActiveSmallCard={setActiveSmallCard}
            />
            <Card
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                cardContent={cardContent}
                activeWindow={activeWindow}
                setActiveWindow={setActiveWindow}
                setWindowContent={setWindowContent}
            />
            <div className="main-first">
                <Header
                    data={data}
                    currentActiveDropdown={currentActiveDropdown}
                    setCurrentActiveDropdown={setCurrentActiveDropdown}
                    directionSwitchChecked={directionSwitchChecked}
                    setDirectionSwitchChecked={setDirectionSwitchChecked}
                    radiusSwitchChecked={radiusSwitchChecked}
                    setRadiusSwitchChecked={setRadiusSwitchChecked}
                    newProjectsSwitchChecked={newProjectsSwitchChecked}
                    setNewProjectsSwitchChecked={setNewProjectsSwitchChecked}
                    masterCommunitiesChecked={masterCommunitiesChecked}
                    setMasterCommunitiesChecked={setMasterCommunitiesChecked}
                    setActiveCard={setActiveCard}
                    activeWindow={activeWindow}
                    setActiveWindow={setActiveWindow}
                    setWindowContent={setWindowContent}
                    activeGuide={activeGuide}
                    activeSmallGuide={activeSmallGuide}
                    setActiveSmallGuide={setActiveSmallGuide}
                    isHeaderActive={isHeaderActive}
                    setIsHeaderActive={setIsHeaderActive}
                    isMobile={isMobile}
                />
                <BottomTips
                    typeDropdowns={typeDropdowns}
                    mapTypeChecked={mapTypeChecked}
                    setMapTypeChecked={setMapTypeChecked}
                    activeWindow={activeWindow}
                    activeGuide={activeGuide}
                    isBottomActive={isBottomActive}
                    setIsBottomActive={setIsBottomActive}
                />
            </div>
            <div className="main-second">
                <Map
                    isLoaded={isLoaded}
                    typeDropdowns={typeDropdowns}
                    setCurrentActiveDropdown={setCurrentActiveDropdown}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    setCardContent={setCardContent}
                    activeWindow={activeWindow}
                    setActiveWindow={setActiveWindow}
                    setWindowContent={setWindowContent}
                    activeSmallCard={activeSmallCard}
                    setActiveSmallCard={setActiveSmallCard}
                    setSmallCardContent={setSmallCardContent}
                    directionSwitchChecked={directionSwitchChecked}
                    setDirectionSwitchChecked={setDirectionSwitchChecked}
                    radiusSwitchChecked={radiusSwitchChecked}
                    setRadiusSwitchChecked={setRadiusSwitchChecked}
                    newProjectsSwitchChecked={newProjectsSwitchChecked}
                    masterCommunitiesChecked={masterCommunitiesChecked}
                    mapTypeChecked={mapTypeChecked}
                    setActiveSmallGuide={setActiveSmallGuide}
                />
            </div>
        </div>
    );
}
