import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { 
    GoogleMap, 
    MarkerF, 
    PolygonF, 
    InfoWindowF, 
    DirectionsRenderer,
    MarkerClustererF, 
    CircleF,
    PolylineF
} from "@react-google-maps/api";

import './Map.scss'

import CarIcon from '../../../static/images/car-icon.png'
import Mark5KM from '../../../static/images/mark-5km.svg'
import Mark10KM from '../../../static/images/mark-10km.svg'
import Mark15KM from '../../../static/images/mark-15km.svg'
import Mark20KM from '../../../static/images/mark-20km.svg'

import { infrastrMarkers, markers, polygons, polylines } from "./Markers";

import { WindowCardBirdView } from "../window-card/WindowCard";



function MapMarker({
    position,
    options,
    icon,

    onMouseOver,
    onMouseOut,
    onClick,

    routeMarkers
}) {
    const markerRef = useRef(null)

    const handleMarkerLoad = (marker) => {
        markerRef.current = marker
        
        markerRef.current.setOpacity(0.5)
    }

    const handleMarkerClick = () => {
        
    }

    useEffect(() => {

        const steps = [1, 0.9, 0.8, 0.7, 0.6, 0.5]
        const stepsReversed = [...steps].reverse()
        let stepTime = 20

        const changeOpacity = (stepsInput) => {
            if (!(markerRef.current.getOpacity() == stepsInput[stepsInput.length - 1])) {
                let currentTime = stepTime
                for (let step of stepsInput) {
                    setTimeout(() => {
                        markerRef.current.setOpacity(step)
                    }, currentTime)
                    currentTime = currentTime + stepTime
                }
            }
        }

        if (routeMarkers.length !== 0) {
            if (routeMarkers.includes(position)) {
                changeOpacity(stepsReversed)
            } else {
                changeOpacity(steps)
            }
        } else {
            changeOpacity(stepsReversed)
        }
    }, [routeMarkers])

    return (
        <MarkerF 
            position={position} 
            options={{
                ...options,
            }}
            icon={icon}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={() => {
                onClick()
                handleMarkerClick()
            }}
            onLoad={handleMarkerLoad}
        >
            {/* {
                activeMarker === id && (
                    <InfoWindowF options={{disableAutoPan: true}}  >
                        <div>{1}</div>
                    </InfoWindowF>
                )
            } */}
        </MarkerF>
    )
}


export default function Map({ 
    isLoaded, 
    typeDropdowns,
    setCurrentActiveDropdown,
    activeCard,
    setActiveCard,
    setCardContent,
    activeWindow,
    setActiveWindow,
    setWindowContent,
    activeSmallCard,
    setActiveSmallCard,
    setSmallCardContent,
    directionSwitchChecked, 
    setDirectionSwitchChecked,
    radiusSwitchChecked,
    setRadiusSwitchChecked,
    newProjectsSwitchChecked,
    masterCommunitiesChecked,
    mapTypeChecked,
    setActiveSmallGuide
}) {

    const center = useMemo(() => ({lat: 25.20539762712896, lng: 55.240262159125635}), [])
    
    const [routeMarkers, setRouteMarkers] = useState([])
    const [circlesCenter, setCirclesCenter] = useState(null)

    const [mapZoom, setMapZoom] = useState()
    const handleZoomChange = () => {
        if (mapRef.current) {
            setMapZoom(mapRef.current.getZoom());
        }
      };

    const mapRef = useRef()

    const [directions, setDirections] = useState()
    const onLoad = useCallback((map) => {
        mapRef.current = map
        mapRef.current.addListener('zoom_changed', handleZoomChange);
        
        mapRef.google = window.google
        mapRef.directionsService = new mapRef.google.maps.DirectionsService()
        mapRef.directionsRenderer = new mapRef.google.maps.DirectionsRenderer()
    }, [])

    const fetchDirections = (coords) => {

        if (!radiusSwitchChecked && coords.lat !== 25.22944996319096) { // additional checking, second condition for the project "The World"
            if (routeMarkers.length === 1 && !routeMarkers.includes(coords)) {
                mapRef.directionsService.route(
                    {
                        origin: routeMarkers[0],
                        destination: coords,
                        travelMode: mapRef.google.maps.TravelMode.DRIVING,
                    },
                    (result, status) => {
                        if (status === 'OK' && result) {
                            const path = result.routes[0].overview_path;
                            const midPointIndex = Math.floor(path.length / 2);
                            mapRef.midPoint = path[midPointIndex];

                            // mapRef.current.setOptions({heading: -45, zoom: 11})
                            mapRef.current.panTo(mapRef.midPoint)

                            setDirections(result)
                            setRouteMarkers([...routeMarkers, coords])
                        }
                    }
                )
            } else if (routeMarkers.length === 2) {
                setDirections(undefined)
                setRouteMarkers([coords])
            } else {
                setRouteMarkers([coords])
            }
        }
    }
    
    const [activeMarker, setActiveMarker] = useState(null)
    const [activeMarker2, setActiveMarker2] = useState(null)
    const openMarkerWindow = (id, data) => {
        switch (data.type) {
            case 'card':
                if (activeCard === id) {
                    setActiveCard(null)
                } else {
                    setCardContent(data)
                    setActiveCard(id)
                }
                setActiveWindow(false)
                break
            case 'bird':
                setWindowContent(null)
                setActiveWindow(true)
                setActiveCard(false)
                
                setTimeout(() => {
                    setWindowContent(<WindowCardBirdView 
                        background={data.background} 
                        projects={data.projects} 
        
                        activeSmallCard={activeSmallCard}
                        setActiveSmallCard={setActiveSmallCard}
                        setSmallCardContent={setSmallCardContent}
                    />)
                }, 10)
                break
            case 'bird_link':
                setWindowContent(null)
                setActiveWindow(true)
                setActiveCard(false)
                
                const birdData = markers.find(marker => marker.id === data.bird_id).content
                setTimeout(() => {
                    setWindowContent(<WindowCardBirdView 
                        background={birdData.background} 
                        projects={birdData.projects} 
        
                        activeSmallCard={activeSmallCard}
                        setActiveSmallCard={setActiveSmallCard}
                        setSmallCardContent={setSmallCardContent}
                    />)
                    
                    // console.log(birdData.projects.find((project) => project.title.replaceAll(' ', '_').toLowerCase() === id))
                    setSmallCardContent(birdData.projects.find((project) => project.id === id))
                    setTimeout(() => {
                        setActiveSmallCard(true)
                    }, 50)
                }, 10)
                break
            default:
                break
        }
        setCurrentActiveDropdown('')
        setActiveSmallGuide(false)
    }

    const handleMarkerClick = (id, coords, data, type) => {
        setCurrentActiveDropdown('')
        setActiveSmallGuide(false)

        if (directionSwitchChecked) {
            fetchDirections(coords)
        } else if (radiusSwitchChecked && type !== 'infrastr') {
            if (!directionSwitchChecked) { // additional checking
                setCirclesCenter(coords)
                // setRouteMarkers([coords])
                setRouteMarkers([])
            }
        } else if (data) {
            openMarkerWindow(id, data)
        }
    }


    // Switch triggers
    useEffect(() => {
        try {
            if (!directionSwitchChecked) {
                setDirections(undefined)
                setRouteMarkers([])
            }
        } catch {}
    }, [directionSwitchChecked])

    useEffect(() => {
        try {
            if (!radiusSwitchChecked) {
                setCirclesCenter(null)
            }
        } catch {}
    }, [radiusSwitchChecked])
    

    // Circles
    useEffect(() => {
        try {
            if (!circlesCenter) {
                setRouteMarkers([])
            }
            mapRef.current.panTo(circlesCenter)
        } catch {}
    }, [circlesCenter])

    
    // Calculations

    const earthRadius = 6378137;
    const metersToLatLng = (distanceInMetersLat, distanceInMetersLng, latitude, longitude) => {
        // Distance from meters to radians
        const distanceInRadiansLat = distanceInMetersLat / earthRadius;
        const distanceInRadiansLng = distanceInMetersLng / earthRadius;

        // Lat and Lng to radians
        const latitudeInRadians = (latitude * Math.PI) / 180;
        const longitudeInRadians = (longitude * Math.PI) / 180;

        // Calcule new lng and lat
        const newLatitudeInRadians = latitudeInRadians + distanceInRadiansLat;
        const newLongitudeInRadians = longitudeInRadians + distanceInRadiansLng;

        // Convert from rad to degrees
        const newLatitude = (newLatitudeInRadians * 180) / Math.PI;
        const newLongitude = (newLongitudeInRadians * 180) / Math.PI;

        return { lat: newLatitude, lng: newLongitude }
    }

    const [mapHeading, setMapHeading] = useState()
    const updateMapHeading = () => {
        if (window.innerWidth < 1120) {
            setMapHeading(-48 + Math.atan(window.innerHeight/window.innerWidth) * 180 / Math.PI)
        } else {
            setMapHeading(-45)
        }
    }
    useEffect(() => {
        updateMapHeading()
    }, [mapHeading])
    window.onresize = updateMapHeading

    
    return (
        <div className="map">
            {
                isLoaded ? (
                    <GoogleMap 
                        zoom={12}
                        center={center}
                        mapContainerClassName="map-container"
                        heading={mapHeading}
                        onLoad={onLoad}
                        onClick={() => {
                            setDirections(undefined)
                            setCirclesCenter(null)
                            setRouteMarkers([])
                            setCurrentActiveDropdown('')
                            setActiveSmallGuide(false)
                            setActiveMarker2(null)
                            setActiveSmallCard(false)
                            if (activeWindow) {
                                // setActiveCard(true)
                                setActiveWindow(false)
                                setWindowContent(null)
                            } else {
                                setActiveCard(null)
                            }
                        }}
                        onDrag={() => {
                            setCurrentActiveDropdown('')
                            setActiveWindow(false)
                            setWindowContent(null)
                            setActiveSmallCard(false)
                            setActiveSmallGuide(false)
                        }}
                        onZoomChanged={() => {
                            setCurrentActiveDropdown('')
                            setActiveWindow(false)
                            setWindowContent(null)
                            setActiveSmallCard(false)
                            setActiveSmallGuide(false)
                        }}
                        options={{ 
                            mapId: "373e84bb40df31a7",
                            disableDefaultUI: true,
                            restriction: {
                                latLngBounds: {
                                  north: 26.261815089520567,
                                  south: 24.210864776343406,
                                  west: 54.259044521019725,
                                  east: 57.016234711938566 
                                },
                                strictBounds: false 
                              },
                              mapTypeId: mapTypeChecked ? 'satellite' : 'roadmap'
                        }}
                    >

                        {/* Infrastructure Markers Mapping */}
                        { 
                            infrastrMarkers.map(({id, allCoords, icon}) => (
                                typeDropdowns.includes(id) && (
                                    allCoords.map(({coords, name}) => (
                                        <MarkerF 
                                            position={{lat: coords[0], lng: coords[1]}} 
                                            icon={icon} 
                                            routeMarkers={routeMarkers}
                                            options={{clickable: true}}
                                            
                                            onMouseOver={() => setActiveMarker(coords)}
                                            onMouseOut={() => setActiveMarker(null)} 
                                            onClick={() => {
                                                handleMarkerClick(1, {lat: coords[0], lng: coords[1]}, null, 'infrastr')
                                                if (!directionSwitchChecked) {
                                                    setActiveMarker2(coords)
                                                }
                                            }}
                                        >
                                            { (activeMarker === coords || activeMarker2 === coords) && (
                                                <InfoWindowF 
                                                    options={{disableAutoPan: true}} 
                                                >
                                                    <div>
                                                        <span>{name ? name : id}</span>
                                                    </div>
                                                </InfoWindowF>
                                            )}
                                        </MarkerF>
                                    ))
                                )
                            ))
                        }

                        {/* Markers Mapping */}
                        {
                            markers.map(({id, checkIsInGroup, groupName, zoomLevel, coords, polygonCoords, icon, activeIcon, clickable, content}) => (
                                (
                                    (
                                        (
                                            checkIsInGroup && groupName.map(
                                                (name) => typeDropdowns.includes(name)
                                            ).filter(item => item === true).length !== 0
                                        )
                                        || 
                                        !checkIsInGroup
                                    )
                                    && 
                                    ( mapZoom > zoomLevel || !zoomLevel)
                                    &&
                                    (
                                        (newProjectsSwitchChecked && groupName.includes('new_project')) 
                                        || 
                                        !newProjectsSwitchChecked
                                        ||
                                        groupName.includes('landmarks')
                                    )
                                ) && (
                                    <MapMarker 
                                        position={coords} 
                                        options={{clickable: clickable.marker, zIndex: 10}}
                                        icon={icon}
                                        onMouseOver={() => setActiveMarker(id)}
                                        onMouseOut={() => setActiveMarker(null)}
                                        onClick={() => 
                                            handleMarkerClick(id, coords, (!(directionSwitchChecked  || radiusSwitchChecked) && content)) // additional checking
                                        }  
                                        routeMarkers={routeMarkers}
                                    />
                                )
                            ))
                        }
                        
                        {/* Polygons Mapping */}
                        {
                            markers.map(({id, checkIsInGroup, groupName, coords, polygonCoords, icon, activeIcon, clickable, content}) => (
                                (polygonCoords && masterCommunitiesChecked) && (
                                    polygonCoords.map((paths) => (
                                        <PolygonF 
                                            paths={paths} 
                                            options={polygonOptions(clickable.polygon)}
                                            onMouseOver={() => setActiveMarker(id)}
                                            onMouseOut={() => setActiveMarker(null)}
                                            onClick={() => handleMarkerClick(id, coords, content)}
                                        />
                                    ))
                                )
                            ))
                        }

                        {/* Polyline Mapping */}
                        {
                            typeDropdowns.includes('transport') && (
                                polylines.map(({id, path, stops, icon, color}) => (
                                    <>
                                        <PolylineF
                                            path={path}
                                            options={{
                                                strokeColor: color, 
                                                strokeOpacity: 1, 
                                                strokeWeight: 4, 
                                                clickable: false
                                            }}
                                        />
                                        { stops.map(({name, coords}) => {
                                            const coordsFormat = {lat: coords[0], lng: coords[1]}
                                            return (
                                                <MarkerF 
                                                    position={coordsFormat} 
                                                    icon={icon} 
                                                    options={{clickable: true, zIndex: 1}}
                                                    onMouseOver={() => setActiveMarker(name)}
                                                    onMouseOut={() => setActiveMarker(null)} 
                                                    onClick={() => setActiveMarker2(name)}
                                                    routeMarkers={routeMarkers}
                                                >
                                                    { (activeMarker === name || activeMarker2 === name) && (
                                                        <InfoWindowF 
                                                            options={{disableAutoPan: true}} 
                                                        >
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                        </InfoWindowF>
                                                    )}
                                                </MarkerF>
                                            )
                                        })}
                                    </>
                                ))
                            )
                        }

                        
                        {/* Drawing Directions */}
                        { (directions && directionSwitchChecked ) && (
                            <>
                                <DirectionsRenderer 
                                    directions={directions} 
                                    options={{
                                        suppressMarkers: true,
                                        polylineOptions: {
                                            zIndex: 500,
                                            strokeColor: "#4DAC89",
                                            strokeWeight: 4,
                                        },
                                        disableAutoPan: true,
                                        preserveViewport: true
                                    }} 
                                />
                                
                                <InfoWindowF position={mapRef.midPoint} >
                                    <div className="directions-infowindow">
                                        <img src={CarIcon} alt="car-icon" />
                                        <span>
                                            {directions.routes[0].legs[0].distance.text + " | " + directions.routes[0].legs[0].duration.text + " "}
                                        </span>
                                    </div>
                                </InfoWindowF>
                            </>
                        ) }


                        {/* Drawing Circles */}
                        { circlesCenter && (
                            <>
                                <CircleF center={circlesCenter} radius={5000} options={circleOptions} />
                                <CircleF center={circlesCenter} radius={10000} options={circleOptions} />
                                <CircleF center={circlesCenter} radius={15000} options={circleOptions} />
                                <CircleF center={circlesCenter} radius={20000} options={circleOptions} />
                                
                                <MarkerF icon={Mark5KM} position={metersToLatLng(3750, -3750, circlesCenter['lat'], circlesCenter['lng'])} options={{clickable: false}} />
                                <MarkerF icon={Mark10KM} position={metersToLatLng(7500, -7500, circlesCenter['lat'], circlesCenter['lng'])} options={{clickable: false}} />
                                <MarkerF icon={Mark15KM} position={metersToLatLng(11250, -11250, circlesCenter['lat'], circlesCenter['lng'])} options={{clickable: false}} />
                                <MarkerF icon={Mark20KM} position={metersToLatLng(15000, -15000, circlesCenter['lat'], circlesCenter['lng'])} options={{clickable: false}} />
                                
                                <MarkerF icon={Mark10KM} position={metersToLatLng(6750, 6750, circlesCenter['lat'], circlesCenter['lng'])} options={{clickable: false}} />
                                <MarkerF icon={Mark20KM} position={metersToLatLng(14000, 14000, circlesCenter['lat'], circlesCenter['lng'])} options={{clickable: false}} />
                            </>
                        ) }
                        
                    </GoogleMap>
                ) : (
                    <h1>Loading...</h1>
                )
            }
        </div>
    )
}


const polygonOptions = (clickable) => ({
    fillColor: "rgba(250, 133, 79, 0.5)",
    fillOpacity: 1,
    strokeColor: "rgba(250, 133, 79, 0.9)",
    strokeOpacity: 0.85,
    strokeWeight: 2,
    clickable: clickable,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
})


const circleOptions = {
    strokeOpacity: 0.75,
    strokeWeight: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    fillOpacity: 0.075,
    strokeColor: "#4DAC89",
    fillColor: "#4DAC89",
  };