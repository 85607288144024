// Additional
import MarkHospital from "../../../static/images/mark-hospital-small.svg";
import MarkSchool from "../../../static/images/mark-school-small.svg";
import MarkShopping from "../../../static/images/mark-shopping-small.svg";

import MarkBus1 from "../../../static/images/mark-bus-1.svg";
import MarkBus2 from "../../../static/images/mark-bus-2.svg";
import MarkBus3 from "../../../static/images/mark-bus-3.svg";

// Projects
import TriaIcon from "../../../static/images/projects/Tria - Deyaar.svg";
import TaliaMilleniumIcon from "../../../static/images/projects/Talia Millenium.svg";
import MontRoseIcon from "../../../static/images/projects/Mont Rose.svg";
import AmaliaIcon from "../../../static/images/projects/Amalia by Deyaar.svg";
import AlDanaTowerIcon from "../../../static/images/projects/Al Dana Tower.svg";
import DeyaarHeadOfficeIcon from "../../../static/images/projects/Deyaar Head Office.svg";
import TheBurlingtonIcon from "../../../static/images/projects/The Burlington.svg";
import TheMetropolisIcon from "../../../static/images/projects/The Metropolis.svg";
import TheCitadelTowerIcon from "../../../static/images/projects/The Citadel Tower.svg";
import FiftyOneBusinessBayIcon from "../../../static/images/projects/Fifty One & Business Bay.svg";
import OxfordTowerIcon from "../../../static/images/projects/Oxford Tower.svg";
import TheAtriaIcon from "../../../static/images/projects/The Atria.svg";
import CoralResidenceIcon from "../../../static/images/projects/Coral Residence.svg";
import RubyResidenceIcon from "../../../static/images/projects/Ruby Residence.svg";
import OakwoodResidenceIcon from "../../../static/images/projects/Oakwood Residence.svg";
import MadisonResidencyIcon from "../../../static/images/projects/Madison Residency.svg";
import AlSeefTowerIcon from "../../../static/images/projects/Al Seef Tower.svg";
import HamiltonResidencyIcon from "../../../static/images/projects/Hamilton Residency.svg";
import FairviewResidencyIcon from "../../../static/images/projects/Fairview Residency.svg";
import MayfairResidencyIcon from "../../../static/images/projects/Mayfair Residency.svg";
import ClaytonResidencyIcon from "../../../static/images/projects/Clayton Residency.svg";
import AlSeef2TowerIcon from "../../../static/images/projects/Al Seef 2 Tower.svg";
import SapphireResidenceIcon from "../../../static/images/projects/Sapphire Residence.svg";
import MayfairTowerIcon from "../../../static/images/projects/Mayfair Tower.svg";
import MidtownIcon from "../../../static/images/projects/Midtown.svg";
import MarCasaIcon from "../../../static/images/projects/Mar Casa.svg";
import RegaliaIcon from "../../../static/images/projects/Regalia.svg";
import MillenniumAtriaIcon from "../../../static/images/projects/Millennium Atria.svg";
import MillenniumAlBarshaIcon from "../../../static/images/projects/Millennium Al Barsha.svg";
import MillenniumMontRoseIcon from "../../../static/images/projects/Millennium Mont Rose.svg";
import RosaliaResidencesIcon from "../../../static/images/projects/Rosalia Residences.svg";
import EleveByDeyaarIcon from "../../../static/images/projects/Eleve by Deyaar.svg";

// Birds
import Bird1Mark from "../../../static/images/birds/bird1/bird1.png";
import Bird1Background from "../../../static/images/birds/bird1/Bird_View_1.jpg";
import Bird1DeyaarHeadOffice from "../../../static/images/birds/bird1/projects/deyaar_head_office.svg";
import { ReactComponent as Bird1DeyaarHeadOfficePolygon } from "../../../static/images/birds/bird1/projects/deyaar_head_office_polygon.svg";
import Bird1MedisonResidency from "../../../static/images/birds/bird1/projects/medison_residency.svg";
import { ReactComponent as Bird1MedisonResidencyPolygon } from "../../../static/images/birds/bird1/projects/medison_residency_polygon.svg";
import Bird1MidtownNoorJannat from "../../../static/images/birds/bird1/projects/midtown_noor_jannat_midtown.svg";
import { ReactComponent as Bird1MidtownNoorJannatPolygon } from "../../../static/images/birds/bird1/projects/midtown_noor_jannat_midtown_polygon.svg";
import Bird1MilleniumAlBarsha from "../../../static/images/birds/bird1/projects/millenium_ai_barsha.svg";
import { ReactComponent as Bird1MilleniumAlBarshaPolygon } from "../../../static/images/birds/bird1/projects/millenium_ai_barsha_polygon.svg";
import Bird1MilleniumMontRose from "../../../static/images/birds/bird1/projects/millenium_mont_rose.svg";
// import { ReactComponent as Bird1MilleniumMontRosePolygon } from '../../../static/images/birds/bird1/projects/millenium_mont_rose_polygon.svg'
import Bird1MontRose from "../../../static/images/birds/bird1/projects/mont_rose.svg";
// import { ReactComponent as Bird1MontRosePolygon } from '../../../static/images/birds/bird1/projects/mont_rose_polygon.svg'
import Bird1OakwoodResidency from "../../../static/images/birds/bird1/projects/oakwood_residency.svg";
import { ReactComponent as Bird1OakwoodResidencyPolygon } from "../../../static/images/birds/bird1/projects/oakwood_residency_polygon.svg";

import Bird2Mark from "../../../static/images/birds/bird2/bird2.png";
import Bird2Background from "../../../static/images/birds/bird2/Bird_View_2.jpg";
import Bird2AlSeefTower from "../../../static/images/birds/bird2/projects/al_seef_tower.png";
import { ReactComponent as Bird2AlSeefTowerPolygon } from "../../../static/images/birds/bird2/projects/al_seef_tower_polygon.svg";
import Bird2AlSeefTower2 from "../../../static/images/birds/bird2/projects/al_seef_tower_2.png";
import { ReactComponent as Bird2AlSeefTower2Polygon } from "../../../static/images/birds/bird2/projects/al_seef_tower_2_polygon.svg";

import Bird3Mark from "../../../static/images/birds/bird3/bird3.png";
import Bird3Background from "../../../static/images/birds/bird3/Bird_View_3.jpg";
import Bird3TriaDeyaar from "../../../static/images/birds/bird3/projects/tria_deyaar.png";
import { ReactComponent as Bird3TriaDeyaarPolygon } from "../../../static/images/birds/bird3/projects/tria_deyaar_polygon.svg";
import Bird3CoralResidence from "../../../static/images/birds/bird3/projects/coral_residence.png";
import { ReactComponent as Bird3CoralResidencePolygon } from "../../../static/images/birds/bird3/projects/coral_residence_polygon.svg";
import Bird3JadeResidence from "../../../static/images/birds/bird3/projects/jade_residence.png";
import { ReactComponent as Bird3JadeResidencePolygon } from "../../../static/images/birds/bird3/projects/jade_residence_polygon.svg";
import Bird3RubyResidence from "../../../static/images/birds/bird3/projects/ruby_residence.png";
import { ReactComponent as Bird3RubyResidencePolygon } from "../../../static/images/birds/bird3/projects/ruby_residence_polygon.svg";
import Bird3SapphireResidence from "../../../static/images/birds/bird3/projects/sapphire_residence.png";
import { ReactComponent as Bird3SapphireResidencePolygon } from "../../../static/images/birds/bird3/projects/sapphire_residence_polygon.svg";

import Bird4Mark from "../../../static/images/birds/bird4/bird4.png";
import Bird4Background from "../../../static/images/birds/bird4/Bird_View_4.jpg";
import Bird4AlDanaTower from "../../../static/images/birds/bird4/projects/al_dana_tower.png";
import { ReactComponent as Bird4AlDanaTowerPolygon } from "../../../static/images/birds/bird4/projects/al_dana_tower_polygon.svg";
import Bird4Burlington from "../../../static/images/birds/bird4/projects/burligton.png";
import { ReactComponent as Bird4BurlingtonPolygon } from "../../../static/images/birds/bird4/projects/burligton_polygon.svg";
import Bird4Clayton from "../../../static/images/birds/bird4/projects/clayton.png";
import { ReactComponent as Bird4ClaytonPolygon } from "../../../static/images/birds/bird4/projects/clayton_polygon.svg";
import Bird4FairviewResidency from "../../../static/images/birds/bird4/projects/fairview_residency.png";
import { ReactComponent as Bird4FairviewResidencyPolygon } from "../../../static/images/birds/bird4/projects/fairview_residency_polygon.svg";
import Bird4FiftyOne from "../../../static/images/birds/bird4/projects/fifty_one.png";
import { ReactComponent as Bird4FiftyOnePolygon } from "../../../static/images/birds/bird4/projects/fifty_one_polygon.svg";
import Bird4Hamilton from "../../../static/images/birds/bird4/projects/hamilton.png";
import { ReactComponent as Bird4HamiltonPolygon } from "../../../static/images/birds/bird4/projects/hamilton_polygon.svg";
import Bird4MarCasa from "../../../static/images/birds/bird4/projects/mar_casa.png";
import { ReactComponent as Bird4MarCasaPolygon } from "../../../static/images/birds/bird4/projects/mar_casa_polygon.svg";
import Bird4MayfairResidence from "../../../static/images/birds/bird4/projects/mayfair_residence.png";
import { ReactComponent as Bird4MayfairResidencePolygon } from "../../../static/images/birds/bird4/projects/mayfair_residence_polygon.svg";
import Bird4MayfairTower from "../../../static/images/birds/bird4/projects/mayfair_tower.png";
import { ReactComponent as Bird4MayfairTowerPolygon } from "../../../static/images/birds/bird4/projects/mayfair_tower_polygon.svg";
import Bird4Oxford from "../../../static/images/birds/bird4/projects/oxford.png";
import { ReactComponent as Bird4OxfordPolygon } from "../../../static/images/birds/bird4/projects/oxford_polygon.svg";
import Bird4RegaliaByDeyaar from "../../../static/images/birds/bird4/projects/regalia_by_deyaar.png";
import { ReactComponent as Bird4RegaliaByDeyaarPolygon } from "../../../static/images/birds/bird4/projects/regalia_by_deyaar_polygon.svg";
import Bird4TheAtria from "../../../static/images/birds/bird4/projects/the_atria.png";
import { ReactComponent as Bird4TheAtriaPolygon } from "../../../static/images/birds/bird4/projects/the_atria_polygon.svg";
import Bird4MillenniumAtria from "../../../static/images/birds/bird4/projects/millennium_atria.png";
import { ReactComponent as Bird4MillenniumAtriaPolygon } from "../../../static/images/birds/bird4/projects/millennium_atria_polygon.svg";
import Bird4TheCitadel from "../../../static/images/birds/bird4/projects/the_citadel.png";
import { ReactComponent as Bird4TheCitadelPolygon } from "../../../static/images/birds/bird4/projects/the_citadel_polygon.svg";
import Bird4TheMetropolis from "../../../static/images/birds/bird4/projects/the_metropolis.png";
import { ReactComponent as Bird4TheMetropolisPolygon } from "../../../static/images/birds/bird4/projects/the_metropolis_polygon.svg";

// Landmarks
import TheViewAtThePalmIcon from "../../../static/images/landmarks/the_view_at_the_palm.svg";
import SalesCentreIcon from "../../../static/images/landmarks/sales_centre.svg";
import ThePalmMonorailIcon from "../../../static/images/landmarks/the_palm_monorail.svg";
import MarinasDubaiIcon from "../../../static/images/landmarks/marinas_dubai_islands.svg";
import DubaiInternationalAirportIcon from "../../../static/images/landmarks/dubai_international_airport.svg";
import DWCAirportIcon from "../../../static/images/landmarks/dwc_airport.svg";
import JumeirahBeachIcon from "../../../static/images/landmarks/jumeirah_beach.svg";
import DubaiCanalIcon from "../../../static/images/landmarks/dubai_canal.svg";

import {
    // Infrastructure
    schools,
    hospitals,
    retails,

    // Polygons

    // Polylines
    bus_road_1,
    bus_road_2,
    bus_road_3,
    bus_road_1_stops,
    bus_road_2_stops,
    bus_road_3_stops,
    bus_road_1_2,
} from "./Coords";

export const infrastrMarkers = [
    {
        id: "health",
        allCoords: hospitals,
        icon: MarkHospital,
    },
    {
        id: "education",
        allCoords: schools,
        icon: MarkSchool,
    },
    {
        id: "retail_or_shopping",
        allCoords: retails,
        icon: MarkShopping,
    },
];

export const markers = [
    {
        id: "tria_deyaar",
        checkIsInGroup: true,
        groupName: ["future_developments"],
        zoomLevel: 0,
        coords: { lat: 25.12504826, lng: 55.37361787 },
        polygonCoords: [],
        icon: TriaIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Tria - Deyaar',
            // previews: [],
            // desc: 'By serving as a testament to the Dubai 2040 Vision, Tria promises an unmatched lifestyle in the beautifully crafted sanctuary. From the attractions in the neighbourhood to the handsomely appointed amenities and from artfully designed, well-tech homes to mesmerising views, all foster a heightened sense and quality of life.',
            // video: null,
            // buttons: [
            //     // {
            //     //     type: "double",
            //     //     data: [
            //     //         {
            //     //             text: "FACT SHEET",
            //     //             data: null
            //     //         },
            //     //         {
            //     //             text: "WEB SITE",
            //     //             data: null
            //     //         }
            //     //     ]
            //     // },
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/tria/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird3",
        },
    },
    {
        id: "mont_rose",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.069341756091543, lng: 55.24579313 },
        polygonCoords: [],
        icon: MontRoseIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            type: "card",
            title: "Mont Rose",
            previews: [],
            desc: "Mont Rose is a three-tower living address that features 19-storey residential towers. The contemporary design with its modern facade, makes this residency an enticing home.",
            video: null,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            text: "WEB SITE",
                            data: null,
                            link: "https://www.deyaar.ae/en/properties/montrose/",
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "al_dana_tower",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.35673679, lng: 55.38797992 },
        polygonCoords: [],
        icon: AlDanaTowerIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Al Dana Tower',
            // previews: [],
            // desc: '',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/al-dana-towers/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "amalia",
        checkIsInGroup: true,
        groupName: ["future_developments"],
        zoomLevel: null,
        coords: { lat: 25.01904442, lng: 55.14419822 },
        polygonCoords: [],
        icon: AmaliaIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            type: "card",
            title: "Amalia by Deyaar",
            previews: [],
            desc: "Perfectly located in an upscale vibrant community, the mid-rise residential tower has been devised with intricate use of light that adds more brightness and space to your home contentment. Amalia Residences offers meticulously designed one, two, and three-bedroom apartments.",
            video: null,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            text: "WEB SITE",
                            data: null,
                            link: "https://www.deyaar.ae/en/properties/amalia/",
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "talia_millenium",
        checkIsInGroup: true,
        groupName: ["asset_management", "future_developments"],
        zoomLevel: null,
        coords: { lat: 25.02046071, lng: 55.15233505 },
        polygonCoords: [],
        icon: TaliaMilleniumIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            type: "card",
            title: "Talia Millenium by Deyaar",
            previews: [],
            desc: "Owners get the chance to enjoy exclusive features, such as access to a state-of-the-art swimming pool, Children’s Play Area, Indoor Gymnasium, Outdoor Training Area, Sauna and Steam Rooms, Prayer Hall, Concierge Desk, Gift Shop, and Food & Beverage outlets.",
            video: null,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            text: "WEB SITE",
                            data: null,
                            link: "https://www.deyaar.ae/en/properties/talia/",
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "deyaar_head_office",
        checkIsInGroup: true,
        groupName: ["commercial_developments"],
        zoomLevel: null,
        coords: { lat: 25.11694525, lng: 55.19676335 },
        polygonCoords: [],
        icon: DeyaarHeadOfficeIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Deyaar Head Office',
            // previews: [],
            // desc: '',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/deyaar-head-office/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird1",
        },
    },
    {
        id: "the_burlington",
        checkIsInGroup: true,
        groupName: ["commercial_developments"],
        zoomLevel: null,
        coords: { lat: 25.18501228, lng: 55.26452025 },
        polygonCoords: [],
        icon: TheBurlingtonIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'The Burlington',
            // previews: [],
            // desc: 'Located in the most prestigious area of Business Bay, The Burlington will showcase some of the most esteemed names in the business world.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/burlington/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "the_metropolis",
        checkIsInGroup: true,
        groupName: ["commercial_developments"],
        zoomLevel: null,
        coords: { lat: 25.1849364, lng: 55.27690069 },
        polygonCoords: [],
        icon: TheMetropolisIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'The Metropolis',
            // previews: [],
            // desc: 'The Metropolis is on par with leading international names in the commercial world, offering position and power of a perfect location in the world’s most powerful business community – the Business Bay of Dubai.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/the-metropolis/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "the_citadel_tower",
        checkIsInGroup: true,
        groupName: ["commercial_developments"],
        zoomLevel: null,
        coords: { lat: 25.18403985, lng: 55.26089546 },
        polygonCoords: [],
        icon: TheCitadelTowerIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'The Citadel Tower',
            // previews: [],
            // desc: 'Located in an enviable position in Business Bay, this corporate business tower boasts 41 floors of beautifully designed office spaces overlooking the bay.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/the-citadel/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "fifty_one_business_bay",
        checkIsInGroup: true,
        groupName: ["commercial_developments"],
        zoomLevel: null,
        coords: { lat: 25.19016094, lng: 55.28427176 },
        polygonCoords: [],
        icon: FiftyOneBusinessBayIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Fifty One & Business Bay',
            // previews: [],
            // desc: 'A state-of-the-art commercial tower, 51 & Business Bay is a perfect harmony of elegance, functionality and convenience.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/fifty-one/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "oxford_tower",
        checkIsInGroup: true,
        groupName: ["commercial_developments"],
        zoomLevel: null,
        coords: { lat: 25.17721836, lng: 55.2732984 },
        polygonCoords: [],
        icon: OxfordTowerIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Oxford Tower',
            // previews: [],
            // desc: 'Oxford Tower is the exclusive address that compliments your business and positions it in the heart of a vibrant business district. Flaunt the stature that your business exudes and join the leading corporate names of the world.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/oxford-tower/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "the_atria",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.18069035, lng: 55.26376082 },
        polygonCoords: [],
        icon: TheAtriaIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'The Atria',
            // previews: [],
            // desc: 'The apartments at The Atria are designed in the yoo Studio Culture Style. Eclectic yet refined, the Culture palette encapsulates a passion for appropriating the most beautiful and exquisitely produced objects and ideas from the past, and placing them alongside the most amazing designs of today.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/the-atria/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "coral_residence",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.10866114, lng: 55.37953722 },
        polygonCoords: [],
        icon: CoralResidenceIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Coral Residence',
            // previews: [],
            // desc: '',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: '/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird3",
        },
    },
    {
        id: "ruby_residence",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.11476999, lng: 55.39024227 },
        polygonCoords: [],
        icon: RubyResidenceIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Ruby Residence',
            // previews: [],
            // desc: '',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: '/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird3",
        },
    },
    {
        id: "oakwood_residence",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.04044145, lng: 55.19246704 },
        polygonCoords: [],
        icon: OakwoodResidenceIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Oakwood Residence',
            // previews: [],
            // desc: 'Completed in 2013 this residential building comprises 18 floors of well-thought, functional, and beautifully finished one and two-bedroom apartments.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/oakwood-residency/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird1",
        },
    },
    {
        id: "madison_residency",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.10025468, lng: 55.17533066 },
        polygonCoords: [],
        icon: MadisonResidencyIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Madison Residency',
            // previews: [],
            // desc: 'Take in a rock concert, catch the buzz of the Dubai International Film Festival, enjoy a round of Golf, or widen your horizons at the Knowledge Village. Indulge in everything from fine cuisine, shopping and trendy clubs, exciting nightlife, upscale shopping malls, designer boutiques, and ethnic gourmet restaurants.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/madison-residency/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird1",
        },
    },
    {
        id: "al_seef_tower",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.09063221, lng: 55.15009494 },
        polygonCoords: [],
        icon: AlSeefTowerIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Al Seef Tower',
            // previews: [],
            // desc: '',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/al-seef-tower/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird2",
        },
    },
    {
        id: "hamilton_residency",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.18606978, lng: 55.28606661 },
        polygonCoords: [],
        icon: HamiltonResidencyIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Hamilton Residency',
            // previews: [],
            // desc: 'With life getting hectic day after day, you probably find that work makes unreasonable demands on your time, leaving you feeling stretched and stressed. Having a home close to your office helps you strike the right balance.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/hamilton-residency/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "fairview_residency",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.18174885, lng: 55.27968833 },
        polygonCoords: [],
        icon: FairviewResidencyIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Fairview Residency',
            // previews: [],
            // desc: 'Completed in 2014, this 18-floor residential tower offers its residents well-designed and comfortable apartments overlooking the Business Bay lake.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/fairview-residency/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "mayfair_residency",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.1819574, lng: 55.27722001 },
        polygonCoords: [],
        icon: MayfairResidencyIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Mayfair Residency',
            // previews: [],
            // desc: 'Offering a choice of 1 and 2 bedroom apartments, Mayfair Residency is designed to impress with its stylish finishes and scenic views of the Business Bay lake.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/mayfair-residency/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "clayton_residency",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.18268817, lng: 55.2829059 },
        polygonCoords: [],
        icon: ClaytonResidencyIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Clayton Residency',
            // previews: [],
            // desc: 'An ultra-contemporary residential project from DEYAAR, Clayton Residency will exemplify the essence of city living.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/clayton-residency/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "al_seef_2_tower",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.07963076, lng: 55.15160723 },
        polygonCoords: [],
        icon: AlSeef2TowerIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Al Seef 2 Tower',
            // previews: [],
            // desc: 'Located in JLT Cluster U, Al Seef 2 tower is part of a three-tower complex which comprises a three-level basement parking facility in addition to extensive retail facilities and a full-floor ultra modern health club.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/al-seef-2-tower/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird2",
        },
    },
    {
        id: "sapphire_residence",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.11436563, lng: 55.38998453 },
        polygonCoords: [],
        icon: SapphireResidenceIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Sapphire Residence',
            // previews: [],
            // desc: 'With finishes of the finest quality, every home at Sapphire Residence exemplifies our commitment to crafting your space with care and finesse.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/sapphire-residence/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird3",
        },
    },
    {
        id: "mayfair_tower",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.18179354, lng: 55.27814602 },
        polygonCoords: [],
        icon: MayfairTowerIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Mayfair Tower',
            // previews: [],
            // desc: 'Every major city on earth has a nerve center – a pulsing, thriving district that anchors the community. These cities centres set the character and tempo of the city, acting as a catalyst for growth, attracting new business and driving the economy',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/mayfair-tower/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "mar_casa",
        checkIsInGroup: true,
        groupName: ["future_developments"],
        zoomLevel: null,
        coords: { lat: 25.2705156, lng: 55.26945654 },
        polygonCoords: [],
        icon: MarCasaIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Mar Casa',
            // previews: [],
            // desc: 'Mar Casa is not just a residence but a gateway to crafting a life that is truly yours. Discover the luxury of emotional development that sets new benchmarks and empowers residents to thrive on their terms.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/mar-casa/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "midtown",
        checkIsInGroup: true,
        groupName: ["future_developments", "residential_developments"],
        zoomLevel: null,
        coords: { lat: 25.02865942, lng: 55.18009174 },
        polygonCoords: [],
        icon: MidtownIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Midtown',
            // previews: [],
            // desc: 'The Midtown life is a vibrant and thriving community you call home. As a resident of Jannat, you’ll not have a space that’s both personal and secure, you’ll also have a whole lot to discover.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/jannatmidtown/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird1",
        },
    },
    {
        id: "regalia",
        checkIsInGroup: true,
        groupName: ["future_developments"],
        zoomLevel: null,
        coords: { lat: 25.18490873, lng: 55.29276712 },
        polygonCoords: [],
        icon: RegaliaIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Regalia',
            // previews: [],
            // desc: 'Regalia’s architecture gracefully honours time and inextricably whispers class. The facade’s textural intrigue and contemporary aesthetic sense make it a stunning landmark amongst its neighbours.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.deyaar.ae/en/properties/regalia/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "millenium_atria",
        checkIsInGroup: true,
        groupName: ["asset_management"],
        zoomLevel: null,
        coords: { lat: 25.17971821, lng: 55.26378552 },
        polygonCoords: [],
        icon: MillenniumAtriaIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Millennium Atria',
            // previews: [],
            // desc: 'Millennium Atria Business bay is located at the heart of the Business Bay district with very close proximity to the Burj Khalifa - the tallest tower in the world, and The Dubai Mall – the largest mall in the world and the Dubai Downtown area, with a convenient access to and from Sheikh Zayed Road and Al Khail Road. Also, Its very close to Business Bay Metro Station only 10 mins by car.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.millenniumhotels.com/en/dubai/millennium-atria-business-bay/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird4",
        },
    },
    {
        id: "millenium_al_barsha",
        checkIsInGroup: true,
        groupName: ["asset_management"],
        zoomLevel: null,
        coords: { lat: 25.11480392, lng: 55.19654048 },
        polygonCoords: [],
        icon: MillenniumAlBarshaIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            // type: 'card',
            // title: 'Millennium Al Barsha',
            // previews: [],
            // desc: 'Conveniently situated in the bustling Al Barsha area, just a short 3 to 5-minute walk from the renowned Mall of the Emirates, Millennium Al Barsha offers a prime location for both business and leisure travelers. With spacious one and two-bedroom options equipped with full kitchens, our 4-star hotel and hotel apartments cater to all your accommodation needs, whether for long or short stays in Dubai.',
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 text: "WEB SITE",
            //                 data: null,
            //                 link: 'https://www.millenniumhotels.com/en/dubai/millennium-al-barsha/'
            //             },
            //         ]
            //     },
            // ]
            type: "bird_link",
            bird_id: "bird1",
        },
    },
    {
        id: "millenium_mont_rose",
        checkIsInGroup: true,
        groupName: ["asset_management"],
        zoomLevel: null,
        coords: { lat: 25.06973031, lng: 55.24494077 },
        polygonCoords: [],
        icon: MillenniumMontRoseIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            type: "card",
            title: "Millennium Mont Rose",
            previews: [],
            desc: "Conveniently located in the heart of Al Barsha, Dubai Science Park, our hotel Millennium Mont Rose Executive Apartments offers a vibrant and growing environment for the science sector where businesses and residents can live and thrive in this innovation savvy neighborhood. Millennium Executive Apartments Montrose is 15 to 20 minutes away from the World Trade Centre.",
            video: null,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            text: "WEB SITE",
                            data: null,
                            link: "https://www.millenniumhotels.com/en/dubai/millennium-executive-apartments-mont-rose/",
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "rosalia_residences",
        checkIsInGroup: true,
        groupName: ["asset_management"],
        zoomLevel: null,
        coords: { lat: 25.03435378, lng: 55.14521669 },
        polygonCoords: [],
        icon: RosaliaResidencesIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            type: "card",
            title: "Rosalia Residences",
            previews: [],
            desc: "Rosalia Residences offers a variety of spacious residential units suitable for families, consisting of one, two and three bedrooms nestled within the serene embrace of nature. Centrally located opposite to the Discovery Gardens 2 Metro Station, Rosalia grants seamless access to vibrant cityscapes.",
            video: null,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            text: "WEB SITE",
                            data: null,
                            link: "https://www.deyaar.ae/en/properties/rosalia-residences/",
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "eleve_by_deyaar",
        checkIsInGroup: true,
        groupName: ["residential_developments"],
        zoomLevel: null,
        coords: { lat: 24.97923341, lng: 55.0932778 },
        polygonCoords: null,
        icon: EleveByDeyaarIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: {
            type: "card",
            title: "Rosalia Residences",
            previews: [],
            desc: "ELEVE, a modern luxury beacon and all-inclusive enclave, soars above New Dubai. Meticulous design, curated podium amenities, and captivating port views grace its striking facade. ",
            video: null,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            text: "WEB SITE",
                            data: null,
                            link: "https://www.deyaar.ae/en/properties/eleve-by-deyaar/",
                        },
                    ],
                },
            ],
        },
    },
    {
        id: "bird1",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.033069569483647, lng: 55.19158250359158 },
        polygonCoords: [],
        icon: Bird1Mark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            type: "bird",
            background: Bird1Background,
            projects: [
                {
                    id: "deyaar_head_office",
                    type: "link",
                    title: "Deyaar Head Office",
                    desc: "",
                    link: "https://www.deyaar.ae/en/properties/deyaar-head-office/",
                    coords: [2145.5, 468.5],
                    lineLength: 150,
                    icon: Bird1DeyaarHeadOffice,
                    polygon: Bird1DeyaarHeadOfficePolygon,
                },
                {
                    id: "madison_residency",
                    type: "card",
                    title: "Madison Residency",
                    desc: "Take in a rock concert, catch the buzz of the Dubai International Film Festival, enjoy a round of Golf, or widen your horizons at the Knowledge Village. Indulge in everything from fine cuisine, shopping and trendy clubs, exciting nightlife, upscale shopping malls, designer boutiques, and ethnic gourmet restaurants.",
                    link: "https://www.deyaar.ae/en/properties/madison-residency/",
                    coords: [1857.5, 497],
                    lineLength: 50,
                    anchor: -180,
                    icon: Bird1MedisonResidency,
                    polygon: Bird1MedisonResidencyPolygon,
                },
                {
                    id: "midtown",
                    type: "card",
                    title: "Midtown",
                    desc: "The Midtown life is a vibrant and thriving community you call home. As a resident of Jannat, you’ll not have a space that’s both personal and secure, you’ll also have a whole lot to discover.",
                    link: "https://www.deyaar.ae/en/properties/jannatmidtown/",
                    coords: [324.5, 1207.5],
                    icon: Bird1MidtownNoorJannat,
                    polygon: Bird1MidtownNoorJannatPolygon,
                },
                {
                    id: "millenium_al_barsha",
                    type: "card",
                    title: "Millennium Al Barsha",
                    desc: "Conveniently situated in the bustling Al Barsha area, just a short 3 to 5-minute walk from the renowned Mall of the Emirates, Millennium Al Barsha offers a prime location for both business and leisure travelers. With spacious one and two-bedroom options equipped with full kitchens, our 4-star hotel and hotel apartments cater to all your accommodation needs, whether for long or short stays in Dubai.",
                    link: "https://www.millenniumhotels.com/en/dubai/millennium-al-barsha/",
                    coords: [2194, 492.5],
                    lineLength: 50,
                    anchor: 140,
                    icon: Bird1MilleniumAlBarsha,
                    polygon: Bird1MilleniumAlBarshaPolygon,
                },
                // {
                //     type: 'card',
                //     title: "Millennium Mont Rose",
                //     desc: "Conveniently located in the heart of Al Barsha, Dubai Science Park, our hotel Millennium Mont Rose Executive Apartments offers a vibrant and growing environment for the science sector where businesses and residents can live and thrive in this innovation savvy neighborhood. Millennium Executive Apartments Montrose is 15 to 20 minutes away from the World Trade Centre.",
                //     link: 'https://www.millenniumhotels.com/en/dubai/millennium-executive-apartments-mont-rose/',
                //     coords: [902, 128],
                //     icon: Bird1MilleniumMontRose
                // },
                // {
                //     type: 'card',
                //     title: "Mont Rose",
                //     desc: "Mont Rose is a three-tower living address that features 19-storey residential towers. The contemporary design with its modern facade, makes this residency an enticing home. ",
                //     link: 'https://www.deyaar.ae/en/properties/montrose/',
                //     coords: [927, 176],
                //     icon: Bird1MontRose
                // },
                {
                    id: "oakwood_residence",
                    type: "card",
                    title: "Oakwood Residency",
                    desc: "Completed in 2013 this residential building comprises 18 floors of well-thought, functional, and beautifully finished one and two-bedroom apartments.",
                    link: "https://www.deyaar.ae/en/properties/oakwood-residency/",
                    coords: [2250.5, 865],
                    icon: Bird1OakwoodResidency,
                    polygon: Bird1OakwoodResidencyPolygon,
                },
            ],
        },
    },
    {
        id: "bird2",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.084571460218655, lng: 55.14590231685604 },
        polygonCoords: [],
        icon: Bird2Mark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            type: "bird",
            background: Bird2Background,
            projects: [
                {
                    id: "al_seef_tower",
                    type: "card",
                    title: "Al Seef Tower",
                    desc: "Located in JLT Cluster U, Al Seef 2 tower is part of a three-tower complex which comprises a three-level basement parking facility in addition to extensive retail facilities and a full-floor ultra modern health club.",
                    link: "https://www.deyaar.ae/en/properties/al-seef-2-tower/",
                    coords: [2941.5, 945.05],
                    icon: Bird2AlSeefTower,
                    polygon: Bird2AlSeefTowerPolygon,
                },
                {
                    id: "al_seef_2_tower",
                    type: "card",
                    title: "Al Seef 2 Tower",
                    desc: "Located in JLT Cluster U, Al Seef 2 tower is part of a three-tower complex which comprises a three-level basement parking facility in addition to extensive retail facilities and a full-floor ultra modern health club.",
                    link: "https://www.deyaar.ae/en/properties/al-seef-2-tower/",
                    coords: [1265.5, 959],
                    icon: Bird2AlSeefTower2,
                    polygon: Bird2AlSeefTower2Polygon,
                },
            ],
        },
    },
    {
        id: "bird3",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.120258561338918, lng: 55.387619010372 },
        polygonCoords: [],
        icon: Bird3Mark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            type: "bird",
            background: Bird3Background,
            projects: [
                {
                    id: "tria_deyaar",
                    type: "card",
                    title: "Tria - Deyaar",
                    desc: "By serving as a testament to the Dubai 2040 Vision, Tria promises an unmatched lifestyle in the beautifully crafted sanctuary. From the attractions in the neighbourhood to the handsomely appointed amenities and from artfully designed, well-tech homes to mesmerising views, all foster a heightened sense and quality of life.",
                    link: "https://www.deyaar.ae/en/properties/tria/",
                    coords: [1853.5, 806.5],
                    icon: Bird3TriaDeyaar,
                    polygon: Bird3TriaDeyaarPolygon,
                },
                {
                    id: "coral_residence",
                    type: "card",
                    title: "Coral Residence",
                    desc: "",
                    link: "",
                    coords: [701, 1045],
                    lineLength: 180,
                    anchor: -50,
                    icon: Bird3CoralResidence,
                    polygon: Bird3CoralResidencePolygon,
                },
                {
                    id: "jade_residence",
                    type: "card",
                    title: "Jade Residence",
                    desc: "",
                    link: "",
                    coords: [812, 1077],
                    lineLength: 70,
                    anchor: 50,
                    icon: Bird3JadeResidence,
                    polygon: Bird3JadeResidencePolygon,
                },
                {
                    id: "ruby_residence",
                    type: "card",
                    title: "Ruby Residence",
                    desc: "",
                    link: "",
                    coords: [1346, 1249.5],
                    lineLength: 50,
                    icon: Bird3RubyResidence,
                    polygon: Bird3RubyResidencePolygon,
                },
                {
                    id: "sapphire_residence",
                    type: "card",
                    title: "Sapphire Residence",
                    desc: "With finishes of the finest quality, every home at Sapphire Residence exemplifies our commitment to crafting your space with care and finesse.",
                    link: "https://www.deyaar.ae/en/properties/sapphire-residence/",
                    coords: [1278, 1241],
                    lineLength: 150,
                    icon: Bird3SapphireResidence,
                    polygon: Bird3SapphireResidencePolygon,
                },
            ],
        },
    },
    {
        id: "bird4",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.185272685809725, lng: 55.27074448374538 },
        polygonCoords: [],
        icon: Bird4Mark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            type: "bird",
            background: Bird4Background,
            projects: [
                {
                    id: "al_dana_tower",
                    type: "link",
                    title: "Al Dana Tower",
                    desc: "",
                    link: "https://www.deyaar.ae/en/properties/al-dana-towers/",
                    coords: [3414, 539],
                    icon: Bird4AlDanaTower,
                    polygon: Bird4AlDanaTowerPolygon,
                },
                {
                    id: "the_burlington",
                    type: "card",
                    title: "The Burlington",
                    desc: "Located in the most prestigious area of Business Bay, The Burlington will showcase some of the most esteemed names in the business world.",
                    link: "https://www.deyaar.ae/en/properties/burlington/",
                    coords: [908, 1164],
                    icon: Bird4Burlington,
                    polygon: Bird4BurlingtonPolygon,
                },
                {
                    id: "clayton_residency",
                    type: "card",
                    title: "Clayton Residency",
                    desc: "An ultra-contemporary residential project from DEYAAR, Clayton Residency will exemplify the essence of city living.",
                    link: "https://www.deyaar.ae/en/properties/clayton-residency/",
                    coords: [2573, 1340],
                    lineLength: 40,
                    icon: Bird4Clayton,
                    polygon: Bird4ClaytonPolygon,
                },
                {
                    id: "fairview_residency",
                    type: "card",
                    title: "Fairview Residency",
                    desc: "Completed in 2014, this 18-floor residential tower offers its residents well-designed and comfortable apartments overlooking the Business Bay lake.",
                    link: "https://www.deyaar.ae/en/properties/fairview-residency/",
                    coords: [2268, 1397],
                    lineLength: 210,
                    icon: Bird4FairviewResidency,
                    polygon: Bird4FairviewResidencyPolygon,
                },
                {
                    id: "fifty_one_business_bay",
                    type: "card",
                    title: "Fifty One & Business Bay",
                    desc: "A state-of-the-art commercial tower, 51& Business Bay is a perfect harmony of elegance, functionality and convenience.",
                    link: "https://www.deyaar.ae/en/properties/fifty-one/",
                    coords: [2556, 1110],
                    icon: Bird4FiftyOne,
                    polygon: Bird4FiftyOnePolygon,
                },
                {
                    id: "hamilton_residency",
                    type: "card",
                    title: "Hamilton Residency",
                    desc: "With life getting hectic day after day, you probably find that work makes unreasonable demands on your time, leaving you feeling stretched and stressed. Having a home close to your office helps you strike the right balance.",
                    link: "https://www.deyaar.ae/en/properties/hamilton-residency/",
                    coords: [2804, 1250],
                    icon: Bird4Hamilton,
                    polygon: Bird4HamiltonPolygon,
                },
                {
                    id: "mar_casa",
                    type: "card",
                    title: "Mar Casa",
                    desc: "Mar Casa is not just a residence but a gateway to crafting a life that is truly yours. Discover the luxury of emotional development that sets new benchmarks and empowers residents to thrive on their terms.",
                    link: "https://www.deyaar.ae/en/properties/mar-casa/",
                    coords: [1856, 605],
                    icon: Bird4MarCasa,
                    polygon: Bird4MarCasaPolygon,
                },
                {
                    id: "mayfair_residency",
                    type: "card",
                    title: "Mayfair Residency",
                    desc: "Offering a choice of 1 and 2 bedroom apartments, Mayfair Residency is designed to impress with its stylish finishes and scenic views of the Business Bay lake.",
                    link: "https://www.deyaar.ae/en/properties/mayfair-residency/",
                    coords: [2019, 1393],
                    lineLength: 10,
                    anchor: -250,
                    icon: Bird4MayfairResidence,
                    polygon: Bird4MayfairResidencePolygon,
                },
                {
                    id: "mayfair_tower",
                    type: "card",
                    title: "Mayfair Tower",
                    desc: "Every major city on earth has a nerve center – a pulsing, thriving district that anchors the community. These cities centres set the character and tempo of the city, acting as a catalyst for growth, attracting new business and driving the economy",
                    link: "https://www.deyaar.ae/en/properties/mayfair-tower/",
                    coords: [2115, 1395],
                    lineLength: 80,
                    anchor: 80,
                    icon: Bird4MayfairTower,
                    polygon: Bird4MayfairTowerPolygon,
                },
                {
                    id: "oxford_tower",
                    type: "card",
                    title: "Oxford Tower",
                    desc: "Oxford Tower is the exclusive address that compliments your business and positions it in the heart of a vibrant business district. Flaunt the stature that your business exudes and join the leading corporate names of the world.",
                    link: "https://www.deyaar.ae/en/properties/oxford-tower/",
                    coords: [1468, 1642],
                    icon: Bird4Oxford,
                    polygon: Bird4OxfordPolygon,
                },
                {
                    id: "regalia",
                    type: "card",
                    title: "Regalia",
                    desc: "Regalia’s architecture gracefully honours time and inextricably whispers class. The facade’s textural intrigue and contemporary aesthetic sense make it a stunning landmark amongst its neighbours.",
                    link: "https://www.deyaar.ae/en/properties/regalia/",
                    coords: [3409, 1058],
                    icon: Bird4RegaliaByDeyaar,
                    polygon: Bird4RegaliaByDeyaarPolygon,
                },
                {
                    id: "the_atria",
                    type: "card",
                    title: "The Atria",
                    desc: "The apartments at The Atria are designed in the yoo Studio Culture Style. Eclectic yet refined, the Culture palette encapsulates a passion for appropriating the most beautiful and exquisitely produced objects and ideas from the past, and placing them alongside the most amazing designs of today.",
                    link: "https://www.deyaar.ae/en/properties/the-atria/",
                    coords: [504, 1413],
                    lineLength: 15,
                    anchor: 145,
                    icon: Bird4TheAtria,
                    polygon: Bird4TheAtriaPolygon,
                },
                {
                    id: "millenium_atria",
                    type: "card",
                    title: "Millennium Atria",
                    desc: "Millennium Atria Business bay is located at the heart of the Business Bay district with very close proximity to the Burj Khalifa - the tallest tower in the world, and The Dubai Mall – the largest mall in the world and the Dubai Downtown area, with a convenient access to and from Sheikh Zayed Road and Al Khail Road. Also, Its very close to Business Bay Metro Station only 10 mins by car.",
                    link: "https://www.millenniumhotels.com/en/dubai/millennium-atria-business-bay/",
                    coords: [493.5, 1437],
                    lineLength: 70,
                    anchor: -185,
                    icon: Bird4MillenniumAtria,
                    polygon: Bird4MillenniumAtriaPolygon,
                },
                {
                    id: "the_citadel_tower",
                    type: "card",
                    title: "The Citadel Tower",
                    desc: "Located in an enviable position in Business Bay, this corporate business tower boasts 41 floors of beautifully designed office spaces overlooking the bay.",
                    link: "https://www.deyaar.ae/en/properties/the-citadel/",
                    coords: [573, 1178.5],
                    anchor: -75,
                    icon: Bird4TheCitadel,
                    polygon: Bird4TheCitadelPolygon,
                },
                {
                    id: "the_metropolis",
                    type: "card",
                    title: "The Metropolis",
                    desc: "The Metropolis is on par with leading international names in the commercial world, offering position and power of a perfect location in the world’s most powerful business community – the Business Bay of Dubai.",
                    link: "https://www.deyaar.ae/en/properties/the-metropolis/",
                    coords: [1967, 1229],
                    lineLength: 160,
                    icon: Bird4TheMetropolis,
                    polygon: Bird4TheMetropolisPolygon,
                },
            ],
        },
    },
    {
        id: "dwc_airport",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 24.885818090910437, lng: 55.158727077847054 },
        polygonCoords: null,
        icon: DWCAirportIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "dubai_canal",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.194975903997243, lng: 55.238651929645435 },
        polygonCoords: null,
        icon: DubaiCanalIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "jumeirah_beach",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.19391473532379, lng: 55.231646559007714 },
        polygonCoords: null,
        icon: JumeirahBeachIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "nakheel_marinas",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.29042993499695, lng: 55.296927953490645 },
        polygonCoords: null,
        icon: MarinasDubaiIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "the_view_at_the_palm",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.112681170745817, lng: 55.13878120002334 },
        polygonCoords: null,
        icon: TheViewAtThePalmIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "palm_monorail",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.100156205311755, lng: 55.15649614474076 },
        polygonCoords: null,
        icon: ThePalmMonorailIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
    {
        id: "international_airport",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.253142380921556, lng: 55.3656575614154 },
        polygonCoords: null,
        icon: DubaiInternationalAirportIcon,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false,
        },
        content: null,
    },
];

export const polylines = [
    {
        id: "bus_station_1",
        path: bus_road_1,
        stops: bus_road_1_stops,
        icon: MarkBus1,
        color: "#EC5650",
    },
    {
        id: "bus_station_1_2",
        path: bus_road_1_2,
        stops: [],
        icon: MarkBus1,
        color: "#EC5650",
    },
    {
        id: "bus_station_2",
        path: bus_road_2,
        stops: bus_road_2_stops,
        icon: MarkBus2,
        color: "#49CD57",
    },
    {
        id: "bus_station_3",
        path: bus_road_3,
        stops: bus_road_3_stops,
        icon: MarkBus3,
        color: "#F26E24",
    },
];
