import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import './SmallCard.scss'

import ExitCross from '../../../static/images/card-exit-cross-black-2.svg'
import ButtonArrow from '../../../static/images/card-arrow.svg'
import DeyaarCircle from '../../../static/images/circle-of-possibilities-4.svg'
import DefaultBgImage from '../../../static/images/default-bg-card.png'



function SmallCardText({ text, className }) {
    return (
        <div className={className}>
            <span className={className + "-text"}>{text}</span>
        </div>
    )
}

function SmallCardImg({ src, className }) {
    return (
        <div className={className}>
            <img src={src} alt={className + "-image"} />
        </div>
    )
}

function SmallCardButton({ text, link }) {
    return (
        <Link 
            to={link}
            target={link && "_blank"}
            className="small-card-elements-button"
        >
            <div className="small-card-elements-button-inner">
                <SmallCardText 
                    className={'small-card-elements-button-label'}
                    text={text}
                />
                <div className="small-card-elements-button-arrow">
                    <img src={ButtonArrow} alt="small-card-arrow-image" />
                </div>
            </div>
        </Link>
    )
}



const SmallCardDiv = styled.div`
    opacity: ${({status}) => status ? '1' : '0'};
    pointer-events: ${({status}) => status ? 'all' : 'none'};

    height: ${({isThereIsLink}) => isThereIsLink ? '168px' : '136px'};
`

const SmallCardPreviewDiv = styled.div`
    background-image: url(${({img}) => img});
`

export default function SmallCard({
    activeSmallCard,
    smallCardContent,
    setActiveSmallCard
}) {
    return (
        <div className="small-card">
            <SmallCardDiv 
                className="small-card-container"
                status={activeSmallCard}
                isThereIsLink={smallCardContent.link}
                style={{ 
                    left: `${
                        (((smallCardContent.coords[0] / 3840) * window.innerWidth) + 400) > window.innerWidth ? (
                            100 *((window.innerWidth - 400)/window.innerWidth)
                        ) : (
                            100 * (smallCardContent.coords[0] / 3840)) }%`,

                    top: `${
                        (((smallCardContent.coords[1] / 2160) * window.innerHeight) + 168) > window.innerHeight ? (
                            100 *((window.innerHeight - 168)/window.innerHeight)
                        ) : (
                            100 * (smallCardContent.coords[1] / 2160)) }%`
                }}
            >
                <div 
                    className="small-card-exit" 
                    onClick={() => setActiveSmallCard(false)} 
                >
                    <img src={ExitCross} alt="small-card-exit-image" />
                </div>
                <SmallCardImg className="small-card-deyaar-circle" src={DeyaarCircle} />
                <SmallCardPreviewDiv 
                    className="small-card-preview" 
                    img={smallCardContent.image ? smallCardContent.image : DefaultBgImage} 
                />
                <div className="small-card-elements">
                    <SmallCardText 
                        className="small-card-elements-title" 
                        text={smallCardContent.title}
                    />
                    {
                        smallCardContent.desc && (
                            <SmallCardText 
                                className="small-card-elements-desc"
                                text={smallCardContent.desc} 
                            />
                        )
                    }
                    {
                        smallCardContent.link && (
                            <SmallCardButton text="WEB SITE" link={smallCardContent.link} />
                        )
                    }
                </div>
            </SmallCardDiv>
        </div>
    )
}