import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Switch from "react-switch";

import './Header.scss'

import MainLogoImg from '../../../static/images/main-logo.svg'
import DropdownArrow from '../../../static/images/dropdown-arrow.svg'
import MasterCommunities from '../../../static/images/master-communities.svg'

import TipImage from '../../../static/images/header/tip.svg'
import TipCrossImage from '../../../static/images/header/tip-cross.svg'

import CircleOfPossibilitiesImage from '../../../static/images/circle-of-possibilities.svg'

import { WindowCardOutsideButton, WindowCardVideo } from "../window-card/WindowCard";

import FingerImage from '../../../static/images/guide/finger.svg'
import ZoomImage from '../../../static/images/guide/zoom.svg'
import ZoomInOutImage from '../../../static/images/guide/zoom_in_out.svg'
import MoveImage from '../../../static/images/guide/move.svg'
import RotateImage from '../../../static/images/guide/rotate.svg'

import TutorialDesktopVideo from '../../../static/videos/tutorial_desktop.mp4'


// Header Elements

function HeaderLogo() {
    return (
        <div onClick={() => window.location.reload()} className="header-logo">
            <div className="header-logo-top">
                <img src={MainLogoImg} alt="nakheel-logo" />
            </div>
            {/* <div className="header-logo-bottom">
                <span className="header-logo-text">Dubai, United Arab Emirates</span>
            </div> */}
        </div> 
    )
}


// Dropdown Elements

const HeaderDropdownButtonDiv = styled.div`
    // border: 1px solid ${({isOpened}) => (isOpened ? '#e6e2e2e5' : '#e6e2e2a8')};
`

const HeaderDropdownArrowImg = styled.img`
    transform: ${({isOpened}) => (isOpened ? 'rotate(180deg)' : 'rotate(0)')}
`

function HeaderDropdownButton({ onClick, title, currentActive, type, isOpened }) {

    const removeExtraChars = () => {
        let extraCharsAmount = title.length + currentActive.length + 2 - 27
        if (extraCharsAmount > 0) {
            return currentActive.slice(0, -extraCharsAmount-2) + '..'
        } else {
            return currentActive
        }
    }
    
    return (
        <HeaderDropdownButtonDiv 
            className="header-dropdown-button" 
            onClick={onClick}
            isOpened={isOpened}
        >
                <span className="header-dropdown-button-text">{
                    type === "radio" ? (
                        <>{title}: <b>{removeExtraChars()}</b></>
                    ) : (
                        title
                    )
                }</span>
                <HeaderDropdownArrowImg 
                    isOpened={isOpened} 
                    src={DropdownArrow} 
                    alt="dropdown-arrow" 
                />
        </HeaderDropdownButtonDiv>
    )
}

function HeaderDropdownListTitle({ title }) {
    return (
        <div className="header-dropdown-list-title">
            <span className="header-dropdown-title-text">{title}</span>
        </div>
    )
}


// Dropdown Radio-List

const RadioInner = styled.div`
    opacity: ${({ activeStatus }) => (activeStatus ? "1" : "0")};
`

function HeaderRadioButton({ activeStatus }) {
    return (
        <div className="header-dropdown-list-radio-button">
            <RadioInner activeStatus={activeStatus} className="header-dropdown-list-radio-button-inner" />
        </div>
    )
}

const HeaderElement = styled.div`
    // background-color: ${({activeStatus}) => (activeStatus ? 'rgb(245, 245, 245)' : '#FFFFFF')};
    // background-color: ${({activeStatus}) => (activeStatus ? 'rgba(226, 226, 226, 0.25)' : 'rgba(0, 0, 0, 0)')};
`

function HeaderDropdownListRadio({ elements, stateObject }) {
    return (
        <>{
            elements.map((element) => {
                let activeStatus = element.id === stateObject ? stateObject.state.id : null
                return (
                    <HeaderElement 
                        onClick={() => stateObject.setState(element)} 
                        className="header-dropdown-list-element"
                        activeStatus={activeStatus}
                    >
                        <div className="header-dropdown-list-element-inner">
                            <HeaderRadioButton activeStatus={activeStatus} />
                            <span className="header-dropdown-list-element-text">{element.text}</span>
                        </div>
                    </HeaderElement>
                )
            })
        }</>
    )
}


// Dropdown Checkbox-List

const ChechboxInner = styled.div`
    opacity: ${({ activeStatus }) => (activeStatus ? "1" : "0")};
`

function HeaderCheckboxButton({ activeStatus }) {
    return (
        <div className="header-dropdown-list-checkbox-button">
            <ChechboxInner activeStatus={activeStatus} className="header-dropdown-list-checkbox-button-inner" />
        </div>
    )
}

function HeaderDropdownListCheckbox({ elements, stateObject, setDirectionSwitchChecked, setRadiusSwitchChecked }) {

    const editState = (elementId) => {
        let stateTemp = [...stateObject.state]

        if (stateObject.state.includes(elementId)) {
            stateTemp.splice(stateTemp.indexOf(elementId), 1)
        } else {
            stateTemp.push(elementId)
        }
        return stateTemp
    }

    const editSeparatedState = (stateObject) => {
        const newState = !stateObject.state
        stateObject.setState(newState)


        // !!! CODE NEEDS TO BE REPLACED !!!
        if (newState === true) {
            if (stateObject.indicator === 'directions') {
                setRadiusSwitchChecked(false)
            } else if (stateObject.indicator === 'radius') {
                setDirectionSwitchChecked(false)
            }
        }
    }



    return (
        <>{
            elements.map((element) => {
                let activeStatus = stateObject ? stateObject.state.includes(element.id) : element.stateObject.state
                return (
                    <HeaderElement 
                        onClick={() => (stateObject ? stateObject.setState(editState(element.id)) : editSeparatedState(element.stateObject))}
                        className="header-dropdown-list-element" 
                        activeStatus={activeStatus}
                    >
                        <div className="header-dropdown-list-element-inner">
                            <span className="header-dropdown-list-element-text">{element.text}</span>
                            <Switch 
                                className="header-switch-element"

                                onChange={() => null}
                                checked={activeStatus} 
                                uncheckedIcon={false}
                                checkedIcon={false}

                                onColor="#E8E8E9" 
                                offColor="#E8E8E9"

                                onHandleColor="#00B087"
                                offHandleColor="#505050"

                                handleDiameter={12}
                                height={20}
                                width={36}
                            />
                        </div>
                    </HeaderElement>
                )
            })
        }</>
    )
}


// Dropdown Element

const HeaderDropdownDiv = styled.div`
    @media screen and (max-width: 1300px) {
        margin-bottom: ${({isOpened}) => (isOpened ? '10px' : '10px')};
        height: ${({isOpened, elementsLen}) => (isOpened ? ((elementsLen*43 + 15 + 43) + 'px') : '43px')};
    }
`

const HeaderDropdownListDiv = styled.div`
    margin-top: ${({isOpened}) => (isOpened ? '53px' : '10px')};
    transform: ${({isOpened, elementsLen}) => (isOpened ? 'scaleY(1)' : 'scaleY(0)')};
    height: ${({isOpened, elementsLen}) => (elementsLen*54) + 'px'};
    // opacity: ${({isOpened}) => (isOpened ? '1' : '0')};

    .header-dropdown-list-element {
        transform: ${({isOpened, elementsLen}) => (isOpened ? 'scaleY(1)' : 'scaleY(0)')};
    }
`

function HeaderDropdown({ 
    type, 
    title, 
    id,
    elements, 
    stateObject,
    currentActiveDropdown,
    setCurrentActiveDropdown,
    setActiveCard
}) {

    const [isOpened, setIsOpened] = useState(false)
    let elementsLen = elements.length

    useEffect(() => {
        if (currentActiveDropdown === id) {
            setIsOpened(true)
        } else {
            setIsOpened(false)
        }
    }, [currentActiveDropdown])

    return (
        <HeaderDropdownDiv 
            className="header-dropdown"
            isOpened={isOpened}
            elementsLen={elementsLen}
        >
            <HeaderDropdownButton 
                onClick={() => {
                    if (currentActiveDropdown !== id) {
                        setCurrentActiveDropdown(id)
                    } else {
                        setCurrentActiveDropdown('')
                    }
                    setActiveCard(null)
                }}
                title={title} 
                currentActive={stateObject ? stateObject.state.text : null} 
                type={type}
                isOpened={isOpened}
            />
            <HeaderDropdownListDiv elementsLen={elementsLen} isOpened={isOpened} className="header-dropdown-list">
                {/* <HeaderDropdownListTitle title={title} /> */}
                
                <div className="header-dropdown-list-elements">
                    {
                        type === 'radio' ? (
                            <HeaderDropdownListRadio 
                                elements={elements} 
                                stateObject={stateObject}
                            />
                        ) : (
                            <HeaderDropdownListCheckbox
                                elements={elements}
                                stateObject={stateObject}
                            />
                        )
                    }
                </div>
            </HeaderDropdownListDiv>
        </HeaderDropdownDiv>
    )
}


// Switch Element
function HeaderSwitch({ 
    switchChecked, 
    setSwitchChecked, 
    setActiveCard,
    labelText,
}) {
    const switchChange = () => {
        setSwitchChecked(!switchChecked)
        setActiveCard(null)
    }

    return (
        <div className="header-switch">
            <div className="header-switch-inner" onClick={switchChange}>
                <Switch 
                    className="header-switch-element"

                    onChange={() => null}
                    checked={switchChecked} 
                    uncheckedIcon={false}
                    checkedIcon={false}

                    onColor="#4DAC89" 
                    offColor="#A4A5A9"

                    handleDiameter={15}
                    height={17}
                    width={31}
                />
                <div className="header-switch-label">
                    <span className="header-switch-text">{labelText}</span>
                </div>
            </div>
        </div>
    )
}

                                            
// Button Element

const HeaderButtonDiv = styled.div`
    background-color: ${({isActive}) => isActive ? '#F88350' : 'rgba(197, 197, 197, 0.153)'};
    // font-weight: ${({isActive}) => isActive ? '500' : '400'};
    // font-size: ${({isActive}) => isActive ? '12.25px' : '12px'};

    &:hover {
        background-color: ${({isActive}) => isActive ? '#DF7949' : 'rgba(226, 226, 226, 0.153)'};
    }
`

const HeaderButtonImage = styled.img`
    transform: ${({isActive}) => isActive ? 'rotate(-0.5turn)' : '0'};
`

function HeaderButton({ text, buttonChecked, setButtonCheched }) {
    return (
        <HeaderButtonDiv 
            className="header-button" 
            isActive={buttonChecked} 
            onClick={() => setButtonCheched(!buttonChecked)}
        >
            <div className="header-button-inner">
                <span className="header-button-text">{text}</span>
                <HeaderButtonImage 
                    src={MasterCommunities} 
                    alt="master-communities-logo" 
                    isActive={buttonChecked}
                />
            </div>
        </HeaderButtonDiv>
    )
}
 

// Circle Of Possibilities

function CircleOfPossibilities() {
    return (
        <div className="header-circle">
            <img src={CircleOfPossibilitiesImage} alt="CircleOfPossibilities" />
        </div>
    )
}


// Guide

function SmallGuideElement({ image, text }) {
    return (
        <div className="header-guide-element">
            <div className="header-guide-element-img">
                <img src={image} alt="header-guide-element-icon" />
            </div>
            <div className="header-guide-element-label">
                <span className="header-guide-element-label-text">
                    {text}
                </span>
            </div>
        </div>
    )
}

const SmallGuideDiv = styled.div`
    margin-top: ${({activeSmallGuide}) => (activeSmallGuide ? '53px' : '10px')};
    transform: ${({activeSmallGuide}) => (activeSmallGuide ? 'scaleY(1)' : 'scaleY(0)')};
    // opacity: ${({activeSmallGuide}) => (activeSmallGuide ? '1' : '0')};
`

function SmallGuide({ activeSmallGuide, isMobile, setActiveWindow, setWindowContent }) {
    return (
        <SmallGuideDiv activeSmallGuide={activeSmallGuide} className="header-guide">
            {
                isMobile ? (
                    <>
                        <SmallGuideElement image={FingerImage} text="Use two fingers to move the map" />
                        <SmallGuideElement image={ZoomImage} text="Pinch to zoom in and zoom out" />
                    </>
                ) : (
                    <>
                        <SmallGuideElement image={ZoomInOutImage} text={["Scroll or pinch to ", <b>zoom in and out</b>]} />
                        <SmallGuideElement image={MoveImage} text={["Hold down ", <b>to move</b>, " the map left and right"]} />
                        <SmallGuideElement image={RotateImage} text={["Hold down Shift + touchpad/left mouse button ", <b>to rotate</b>]} />
                    </>
                )
            }
            <div 
                className="header-guide-button"
                onClick={() => {
                    setActiveWindow(true)
                    setWindowContent(<>
                        <WindowCardVideo video={TutorialDesktopVideo} />
                        { !isMobile && (
                            <WindowCardOutsideButton
                                text={'SKIP'}
                                onClick={() => setActiveWindow(false)}
                            />
                        )}
                    </>)
                }} 
            >
                <span className="header-guide-button-text">VIDEO TUTORIAL</span>
            </div>
        </SmallGuideDiv>
    )
}


const HeaderDiv = styled.div`
    opacity: ${({ isHeaderActive }) => (isHeaderActive ? '100%' : "0%")};

    * {
        pointer-events: ${({ isHeaderActive }) => (isHeaderActive ? 'all' : "none")} !important;
    }
`

export default function Header({ 
    data, 

    currentActiveDropdown, 
    setCurrentActiveDropdown, 

    directionSwitchChecked,
    setDirectionSwitchChecked,
    radiusSwitchChecked,
    setRadiusSwitchChecked,
    newProjectsSwitchChecked,
    setNewProjectsSwitchChecked,
    masterCommunitiesChecked,
    setMasterCommunitiesChecked,

    setActiveCard,
    
    activeWindow,
    setActiveWindow,
    setWindowContent,

    activeGuide,
    setActiveSmallGuide,
    activeSmallGuide,

    isHeaderActive,
    setIsHeaderActive,

    isMobile
}) {

    useEffect(() => {
        activeGuide || activeWindow ? (
            setIsHeaderActive(false)
        ) : (
            setIsHeaderActive(true)
        )
    }, [activeGuide, activeWindow])
    
    return (
        <HeaderDiv className="header" isHeaderActive={isHeaderActive}>
            <HeaderLogo />
            <CircleOfPossibilities />
            <div className="header-container">
                <div className="header-panel">
                    <div className="header-panel-top">
                        {
                            data.map((dropdown) => (
                                <HeaderDropdownButton 
                                    onClick={() => {
                                        if (currentActiveDropdown !== dropdown.id) {
                                            setCurrentActiveDropdown(dropdown.id)
                                            setActiveSmallGuide(false)
                                        } else {
                                            setCurrentActiveDropdown(null)
                                        }
                                        setActiveCard(null)
                                    }}
                                    title={dropdown.title} 
                                    currentActive={dropdown.stateObject?.state.text} 
                                    type={dropdown.type}
                                    isOpened={currentActiveDropdown === dropdown.id}
                                />
                            ))
                        }
                        <div 
                            className="header-panel-top-tip"
                            onClick={() => (
                                activeSmallGuide ? (
                                    setActiveSmallGuide(false)
                                ) : (
                                    setActiveSmallGuide(true),
                                    setCurrentActiveDropdown(null)
                                ),
                                setActiveCard(null)
                            )}
                            style={{
                                backgroundImage: 'url(' + (activeSmallGuide ? TipCrossImage : TipImage) + ')',
                                backgroundSize: 'cover'
                            }}
                        />
                    </div>
                    <div className="header-panel-bottom">
                        {
                            data.map((dropdown) => (
                                <HeaderDropdownListDiv elementsLen={dropdown.elementsLen} isOpened={currentActiveDropdown === dropdown.id} className="header-dropdown-list">
                                    {/* <HeaderDropdownListTitle title={title} /> */}
                                    
                                    <div className="header-dropdown-list-elements">
                                        {
                                            dropdown.type === 'radio' ? (
                                                <HeaderDropdownListRadio 
                                                    elements={dropdown.elements} 
                                                    stateObject={dropdown.stateObject}
                                                />
                                            ) : (
                                                <HeaderDropdownListCheckbox
                                                    elements={dropdown.elements}
                                                    stateObject={dropdown.stateObject}

                                                    // Temporary
                                                    setDirectionSwitchChecked={setDirectionSwitchChecked}
                                                    setRadiusSwitchChecked={setRadiusSwitchChecked}
                                                />
                                            )
                                        }
                                    </div>
                                </HeaderDropdownListDiv>
                            ))
                        }
                        <SmallGuide 
                            activeSmallGuide={activeSmallGuide} 
                            isMobile={isMobile} 
                            setActiveWindow={setActiveWindow}
                            setWindowContent={setWindowContent}
                        />
                    </div>
                </div>
                {/* <div className="header-left">
                    <HeaderLogo />
                </div> */}
                {/* <div className="header-right">
                    {
                        data.map((dropdown) => (
                            <HeaderDropdown 
                                type={dropdown.type}
                                title={dropdown.title} 
                                id={dropdown.id}
                                elements={dropdown.elements}
                                stateObject={dropdown.stateObject}

                                currentActiveDropdown={currentActiveDropdown}
                                setCurrentActiveDropdown={setCurrentActiveDropdown}

                                setActiveCard={setActiveCard}
                            />
                        ))
                    }
                    <HeaderButton 
                        text="Master Communities" 
                        buttonChecked={masterCommunitiesChecked}
                        setButtonCheched={setMasterCommunitiesChecked} 
                    />
                    <div className="header-right-switchbox">
                        <HeaderSwitch 
                            switchChecked={directionSwitchChecked} 
                            setSwitchChecked={setDirectionSwitchChecked} 
                            setActiveCard={setActiveCard}
                            labelText='Show Distances'
                        />
                        <HeaderSwitch 
                            switchChecked={radiusSwitchChecked} 
                            setSwitchChecked={setRadiusSwitchChecked} 
                            setActiveCard={setActiveCard}
                            labelText='Show Circles'
                        />
                        <HeaderSwitch 
                            switchChecked={newProjectsSwitchChecked} 
                            setSwitchChecked={setNewProjectsSwitchChecked} 
                            setActiveCard={setActiveCard}
                            labelText='New Projects'
                        />
                    </div>
                </div> */}
            </div>
        </HeaderDiv>
    )
}